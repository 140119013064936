import { Component, Input } from '@angular/core';
import { IBrandCard } from '../../../core/models/section.interface';
import { IBrand } from '../../../standalone/grid-section/grid-section.interface';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'rsc-brand-card',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './brand-card.component.html',
  styleUrl: './brand-card.component.scss',
})
export class BrandCardComponent {
  @Input() element?: IBrandCard;
  @Input() brandData?: IBrand;
}
