<div
  class="mid-section"
  [style.margin-left]="section.sideMargins || '0%'"
  [style.margin-right]="section.sideMargins || '0%'"
  [style.width]="calculateWidth(section.sideMargins)"
  [style.min-height]="renderedHeight"
  [style.background-image]="section.backgroundImage ? 'url(' + section.backgroundImage + ')' : null"
  [style.background-color]="section.backgroundColor || null"
  [style.padding-top]="section.topPadding ? section.topPadding + '%' : '0%'"
  [style.padding-bottom]="section.bottomPadding ? section.bottomPadding + '%' : '0%'"
  [style.padding-left]="section.sidePadding ? section.sidePadding + '%' : '0%'"
  [style.padding-right]="section.sidePadding ? section.sidePadding + '%' : '0%'">
  <!-- Section Title and Description -->
  <div class="overlay" [style.justify-content]="section.hAlignTitle || 'center'">
    <h1 *ngIf="section.firstTitle" [style.color]="section.textColor || null">
      {{ section.title }}
    </h1>
    <h2 *ngIf="!section.firstTitle" [style.color]="section.textColor || null">
      {{ section.title }}
    </h2>
    <p [style.color]="section.textColor">{{ section.subtitle }}</p>
  </div>

  <!-- Subsections -->
  <div class="subsections">
    <div *ngFor="let subsection of section.subsections">
      <div class="subsection-elements" [style.justify-content]="subsection.alignment || 'center'">
        <!-- Subsection Elements -->
        <div *ngFor="let element of subsection.elements" [ngSwitch]="element.type">
          <!-- Icon Card Element -->
          <ng-container *ngSwitchCase="'iconCard'">
            <rsc-icon-card [element]="element"></rsc-icon-card>
          </ng-container>

          <!-- Button Element -->
          <ng-container *ngSwitchCase="'buttonElement'">
            <rsc-button [button]="{ fields: element }"></rsc-button>
          </ng-container>
          <!--Link Button Element -->
          <ng-container *ngSwitchCase="'linkButton'">
            <rsc-link-button [element]="{ fields: element }"></rsc-link-button>
          </ng-container>

          <!-- Text Card Element -->
          <ng-container *ngSwitchCase="'textCard'">
            <rsc-text-card [element]="element"></rsc-text-card>
          </ng-container>

          <!-- Divider Element -->
          <ng-container *ngSwitchCase="'dividerComponent'">
            <rsc-divider [element]="element.divider"></rsc-divider>
          </ng-container>
          <!-- Image Element -->
          <ng-container *ngSwitchCase="'imageElement'">
            <img
              [style.height.px]="element['imageHeight'] || null"
              [style.width.px]="element['imageWidth'] || null"
              [src]="element['imageUrl'].fields?.file?.url" />
          </ng-container>
          <!-- Default Case -->
          <ng-container *ngSwitchDefault>
            <p>Unsupported element type: {{ element.type }}</p>
          </ng-container>
        </div>
        <!-- 
  <div class="d-flex flex-column" *ngIf="section.subsections?.length">
    <rsc-subsection
      *ngFor="let subsection of section.subsections"
      [element]="subsection"></rsc-subsection>
  </div> -->
      </div>
    </div></div
  ></div
>
