import { Component, Input } from '@angular/core';
import { ILinkButton } from '../../../core/models/section.interface';

@Component({
  selector: 'rsc-link-button',
  standalone: true,
  imports: [],
  templateUrl: './link-button.component.html',
  styleUrl: './link-button.component.scss',
})
export class LinkButtonComponent {
  @Input() element!: ILinkButton | any;
}
