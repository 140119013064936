import { Injectable } from '@angular/core';
import { IElement, ISection } from '../models/section.interface';
import { ContentfulService } from './contentful.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private contentfulService: ContentfulService,
    private languageService: LanguageService,
  ) {}

  // Fetch homepage data and map it into Section objects
  async getHomePage(): Promise<ISection[]> {
    const response = await this.contentfulService.getHomePage();

    if (!response || !response.items || response.items.length === 0) {
      throw new Error('No homepage data found');
    }

    this.languageService.currentLanguageSubject.next(response.items[0].sys.locale || 'en-US');
    const homepageEntry = response.items[0];
    const sections = homepageEntry.fields['sections'] as any[];

    const entries = this.mapIncludes(response.includes?.Entry || []);
    const assets = this.mapIncludes(response.includes?.Asset || []);

    let isFirstTitleFound = false;

    const mappedSections = sections
      .map((sectionLink: any) => {
        const sectionEntry = entries[sectionLink.sys.id];

        if (!sectionEntry) {
          console.warn(`Section not found for ID: ${sectionLink.sys.id}`);
          return null;
        }

        const type =
          sectionEntry.fields.apiIdentifier ||
          sectionEntry.fields.type ||
          sectionEntry.sys.contentType?.sys?.id;

        if (!type) {
          console.warn(
            `No 'type' or 'apiIdentifier' defined for section with ID: ${sectionLink.sys.id}`,
          );
          return null;
        }

        const hasTitle = sectionEntry.fields.title && !isFirstTitleFound;

        const section: any = {
          id: sectionEntry.sys.id,
          type: type,
          ...sectionEntry.fields,
          firstTitle: !isFirstTitleFound && hasTitle,
          sidePadding: homepageEntry.fields['sidePadding'],
          subsections: this.mapSubsections(sectionEntry.fields.subsections, entries, assets),
        };

        if (!isFirstTitleFound && hasTitle) {
          isFirstTitleFound = true;
        }

        if (section.backgroundImage?.sys?.id) {
          const asset = assets[section.backgroundImage.sys.id];
          section.backgroundImage = asset?.fields.file.url
            ? `https:${asset.fields.file.url}`
            : null;
        }

        return section;
      })
      .filter((section) => section !== null);

    return [...mappedSections];
  }

  private mapSubsections(
    subsections: any[],
    entries: Record<string, any>,
    assets: Record<string, any>,
  ): any[] {
    if (!subsections || !Array.isArray(subsections)) return [];

    return subsections
      .map((subsectionLink: any) => {
        const subsectionEntry = entries[subsectionLink.sys.id];

        if (!subsectionEntry) {
          console.warn(`Subsection not found for ID: ${subsectionLink.sys.id}`);
          return null;
        }

        const subsectionType =
          subsectionEntry.fields.type || subsectionEntry.sys.contentType?.sys?.id || 'unknown';

        return {
          id: subsectionEntry.sys.id,
          type: subsectionType,
          alignment: subsectionEntry.fields.alignment || 'center',
          titleSize: subsectionEntry.fields.titleSize || 'h2',
          subtitle: subsectionEntry.fields.subtitle || null,
          subtitleSize: subsectionEntry.fields.subtitleSize || 'p',
          titlePosition: subsectionEntry.fields.titlePosition || 'center',
          elements: this.mapElements(subsectionEntry.fields.elements, entries, assets),
        };
      })
      .filter((subsection) => subsection !== null);
  }

  private mapElements(
    elements: any[],
    entries: Record<string, any>,
    assets: Record<string, any>,
  ): IElement[] {
    if (!elements || !Array.isArray(elements)) return [];

    return elements
      .map((elementLink: any) => {
        const elementEntry = entries[elementLink.sys.id];

        if (!elementEntry) {
          console.warn(`Element not found for ID: ${elementLink.sys.id}`);
          return null;
        }

        const elementType =
          elementEntry.fields.type || elementEntry.sys.contentType?.sys?.id || 'unknown';

        const mappedElement: IElement | any = {
          id: elementEntry.sys.id,
          type: elementType,
          alignment: elementEntry.fields.alignment || 'center',
          titleSize: elementEntry.fields.titleSize || 'h2', // Title size
          subtitle: elementEntry.fields.subtitle || null, // Subtitle
          subtitleSize: elementEntry.fields.subtitleSize || 'p', // Subtitle size
          ...elementEntry.fields,
        };

        if (mappedElement.backgroundImage?.sys?.id) {
          const asset = assets[mappedElement.backgroundImage.sys.id];
          mappedElement.backgroundImage = asset?.fields.file.url
            ? `https:${asset.fields.file.url}`
            : null;
        }

        return mappedElement;
      })
      .filter((element) => element !== null);
  }

  private mapIncludes(includes: any[]): Record<string, any> {
    return includes.reduce((acc, include) => {
      acc[include.sys.id] = include;
      return acc;
    }, {});
  }
}
