<button
  class="button"
  (click)="redirectToUrl(button?.fields?.hyperlink)"
  [style.background-color]="button?.fields?.backgroundColor || null"
  [style.color]="button?.fields?.buttonTextColor || null"
  [style.border-color]="button?.fields?.borderColor || null"
  [style.border-radius.px]="button?.fields?.borderRadius || null"
  [style.border-width.px]="button?.fields?.borderThickness || null"
  [style.padding-top.px]="button?.fields?.verticalPadding ?? 0"
  [style.padding-bottom.px]="button?.fields?.verticalPadding ?? 0"
  [style.padding-right.px]="button?.fields?.horizontalPadding ?? 0"
  [style.padding-left.px]="button?.fields?.horizontalPadding ?? 0">
  {{ button?.fields?.buttonText }}
</button>
