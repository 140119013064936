import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContentfulService } from '../../core/services/contentful.service';
import { DividerComponent } from '../../shared/components/divider/divider.component';
import { TextCardComponent } from '../../shared/components/text-card/text-card.component';

@Component({
  selector: 'rsc-footer-section',
  standalone: true,
  imports: [CommonModule, TextCardComponent, DividerComponent],
  templateUrl: './footer-section.component.html',
  styleUrl: './footer-section.component.scss',
})
export class FooterSectionComponent {
  logoUrl?: string;

  constructor(private contentfulService: ContentfulService) {}
  footerData: any;
  ngOnInit() {
    this.contentfulService
      .getFooter()
      .then((response) => {
        if (response && response.items && response.items.length > 0) {
          this.footerData = response.items[0].fields; // Assuming single footer entry
          console.log('Footer Data:', this.footerData);
          this.logoUrl = this.footerData?.logoIcon?.fields?.imageUrl?.fields?.file?.url.startsWith(
            '//',
          )
            ? 'https:' + this.footerData.logoIcon.fields.imageUrl.fields.file.url
            : this.footerData?.logoIcon?.fields?.imageUrl?.fields?.file?.url;
        }
      })
      .catch((error) => {
        console.error('Error fetching footer data:', error);
      });
  }
}
