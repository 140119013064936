import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ISection } from '../../core/models/section.interface';
import { RscServiceService } from '../../core/services/rsc-service.service';
import { BrandCardComponent } from '../../shared/components/brand-card/brand-card.component';
import { LinkButtonComponent } from '../../shared/components/link-button/link-button.component';
import { IBrandListResponse } from './grid-section.interface';

@Component({
  standalone: true,
  selector: 'rsc-grid-section',
  imports: [CommonModule, BrandCardComponent, LinkButtonComponent],
  templateUrl: './grid-section.component.html',
  styleUrls: ['./grid-section.component.scss'],
})
export class GridSectionComponent implements OnChanges {
  @Input() section!: ISection;
  items: any[] = [];
  button = false;

  constructor(
    private _rscService: RscServiceService,
    private http: HttpClient,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      this._rscService
        .getBrands$(this.section.customizedPlatform)
        .subscribe((data: IBrandListResponse) => {
          this.items = data.results;
          this.button = !!data.next;
        });
    }
  }

  redirectTo(url: string): void {
    window.open(url, '_blank');
  }
}
