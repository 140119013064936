import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { tap } from 'rxjs';
import { ContentfulService } from '../../../core/services/contentful.service';
import { LanguageService } from '../../../core/services/language.service';

@Component({
  selector: 'rsc-language-switcher',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss',
})
export class LanguageSwitcherComponent {
  @Input() element?: any;

  private contentfulService = inject(ContentfulService);
  private languageService = inject(LanguageService);

  isDropdownOpen = false;

  selectedOption: string | null = null;
  locales?: any;

  selectedOption$ = this.languageService.currentLanguageSubject
    .pipe(tap((res) => (this.selectedOption = res)))
    .subscribe();

  ngOnInit(): void {
    this.getLocales();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option: any) {
    this.isDropdownOpen = false;
    this.languageService.currentLanguageSubject.next(option.code);
    window.location.href = `/${option.code}`;
  }

  async getLocales() {
    try {
      this.locales = await this.contentfulService.getLocales();
    } catch (error) {
      console.error('Error fetching locales', error);
    }
  }
}
