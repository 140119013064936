<a href="{{ element.fields?.hyperlink }}">
  <div
    class="link-button"
    [style.background-color]="element.fields?.backgroundColor || null"
    [style.border-radius.px]="element.fields?.borderRadius || null"
    [style.border-color]="element.fields?.borderColor || null"
    [style.padding-top.px]="element.fields?.verticalPadding || null"
    [style.padding-bottom.px]="element.fields?.verticalPadding || null"
    [style.padding-right.px]="element.fields?.horizontalPadding || 0"
    [style.padding-left.px]="element.fields?.horizontalPadding || 0"
    >{{ element.fields?.buttonText }}
    <img
      [src]="element.fields?.icon?.fields?.file?.url"
      style="height: 12px; width: 12px; margin-left: 5px"
  /></div>
</a>
