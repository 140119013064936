<div
  class="dropdown"
  [style.background-color]="element?.fields?.backgroundColor || null"
  [style.border-color]="element?.fields?.borderColor || null"
  [style.padding-bottom.px]="element?.fields?.paddingVertical"
  [style.padding-top.px]="element?.fields?.paddingVertical"
  [style.border-radius.px]="element?.fields?.borderRadius ?? 0"
  [style.padding-left.px]="element?.fields?.paddingHorizontal"
  [style.padding-right.px]="element?.fields?.paddingHorizontal">
  <div (click)="toggleDropdown()">
    {{ selectedOption || 'Select an option' }}
  </div>
  <ul class="dropdown-menu" [class.show]="isDropdownOpen" aria-labelledby="dropdownMenuButton">
    <li *ngFor="let option of locales?.items" (click)="selectOption(option)">
      <a class="dropdown-item">{{ option.name }}</a>
    </li>
  </ul>
</div>
