import { Component } from '@angular/core';
import { RouterModule, RouterOutlet, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { FooterSectionComponent } from './standalone/footer-section/footer-section.component';
import { HeaderSectionComponent } from './standalone/header-section/header-section.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // Add other routes if needed
];

@Component({
  selector: 'rsc-root',
  standalone: true,
  imports: [RouterModule, RouterOutlet, HeaderSectionComponent, FooterSectionComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'rsc-standard';
}
