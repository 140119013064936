export const environment = {
  production: false,
  // API URLs
  apiUrl: process.env['API_URL'] || 'https://backsass.respect-code.org',
  baseApiUrl: process.env['BASE_API_URL'] || 'https://snowflake-backoffice.respect-code.org/',
  // Asset Path
  assetPath: '../assets/',
  // Mapbox Configuration
  mapboxToken: process.env['MAPBOX_TOKEN'] || '',
  mapboxStyle: process.env['MAPBOX_STYLE'] || '',
  // Sentry
  enableSentry: true,
  // Contentful Configuration
  contentful: {
    contentfulToken: process.env['CONTENTFUL_TOKEN'] || '',
    environment: process.env['CONTENTFUL_ENV'] || 'QA',
  },
};
