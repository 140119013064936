<div
  class="grid-section"
  [style.background-color]="section.backgroundColor || null"
  [style.padding-left]="section.sidePadding + '%' || '0%'"
  [style.padding-right]="section.sidePadding + '%' || '0%'"
  [style.padding-top]="section.topPadding ? section.topPadding + '%' : '0%'"
  [style.padding-bottom]="section.bottomPadding ? section.bottomPadding + '%' : '0%'">
  <div [style.text-align]="section.hAlignTitle || 'center'" class="overlay">
    <h1 *ngIf="section.firstTitle" [style.color]="section.textColor || null" class="title">
      {{ section.title }}
    </h1>
    <h2 *ngIf="!section.firstTitle" [style.color]="section.textColor || null" class="title">
      {{ section.title }}
    </h2>
    <p class="subtitle" [style.color]="section.textColor">{{ section.subtitle }}</p>
  </div>
  <div class="grid-items">
    <rsc-brand-card
      *ngFor="let brand of items"
      [element]="section.brandCard"
      [brandData]="brand"></rsc-brand-card>
  </div>
  <div *ngIf="button" class="btn-wrp">
    <div
      *ngIf="section.linkButton"
      class="d-flex flex-row align-items-center justify-content-center gap-4">
      <rsc-link-button
        *ngFor="let button of section.linkButton"
        [element]="button"></rsc-link-button>
    </div>
  </div>
</div>
