import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { HomeService } from '../../core/services/home.service';
import { GridSectionComponent } from '../../standalone/grid-section/grid-section.component';
import { MidSectionComponent } from '../../standalone/mid-section/mid-section.component';

@Component({
  selector: 'rsc-home',
  standalone: true,
  imports: [GridSectionComponent, MidSectionComponent, CommonModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  private homeService = inject(HomeService);
  homepage = signal<any[]>([]);

  ngOnInit(): void {
    this.homeService.getHomePage().then((data) => {
      this.homepage.set(data);
    });
  }
}
