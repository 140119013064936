<div
  class="header-section"
  [style.background-color]="headerData?.button?.fields?.backgroundColor || null"
  [style.padding-left]="headerData?.sidePadding + '%' || '0%'"
  [style.padding-right]="headerData?.sidePadding + '%' || '0%'"
  [style.padding-top]="headerData?.verticalPadding ? headerData.verticalPadding + '%' : '0%'"
  [style.padding-bottom]="headerData?.verticalPadding ? headerData.verticalPadding + '%' : '0%'">
  <div
    class="d-flex align-items-center"
    [class]="headerData?.contentPosition || null"
    [style.padding.px]="headerData?.button?.fields?.paddingAround ?? 0">
    <div *ngFor="let element of headerData?.elements">
      <rsc-button
        *ngIf="element.sys.contentType.sys.id === 'buttonElement'"
        [button]="element"></rsc-button>

      <rsc-language-switcher
        *ngIf="element.sys.contentType.sys.id === 'languageSwitcher'"
        [element]="element"></rsc-language-switcher>

      <div *ngIf="element.sys.contentType.sys.id === 'imageElement'">
        <img
          [style.height.px]="element.fields.imageHeight"
          [style.width.px]="element.fields.imageWidth"
          [src]="element.fields.imageUrl.fields?.file?.url" />
      </div>
    </div>
  </div>
</div>
