import { Component, Input } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  selector: 'rsc-text-card',
  standalone: true,
  imports: [],
  templateUrl: './text-card.component.html',
  styleUrl: './text-card.component.scss',
})
export class TextCardComponent {
  @Input() element?: any;

  richTextHtml: string = '';

  ngOnInit(): void {
    this.richTextHtml = documentToHtmlString(this.element.fields.text);
  }
}
